/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-preview.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../default-preview/default-preview.component.ngfactory";
import * as i3 from "../default-preview/default-preview.component";
import * as i4 from "../current-preview-file";
import * as i5 from "../preview-url-transformer";
import * as i6 from "../../core/config/settings.service";
import * as i7 from "../../core/http/app-http-client.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../auth/current-user";
import * as i10 from "@angular/common";
import * as i11 from "./text-preview.component";
var styles_TextPreviewComponent = [i0.styles];
var RenderType_TextPreviewComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TextPreviewComponent, data: {} });
export { RenderType_TextPreviewComponent as RenderType_TextPreviewComponent };
function View_TextPreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "default-preview", [["message", "This file is too large to preview."]], null, null, null, i2.View_DefaultPreviewComponent_0, i2.RenderType_DefaultPreviewComponent)), i1.ɵdid(1, 49152, null, 0, i3.DefaultPreviewComponent, [i4.CURRENT_PREVIEW_FILE, i5.PREVIEW_URL_TRANSFORMER, i6.Settings, i7.AppHttpClient, i8.DomSanitizer, i9.CurrentUser], { message: [0, "message"] }, null)], function (_ck, _v) { var currVal_0 = "This file is too large to preview."; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TextPreviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "text-wrapper preview-object"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "pre", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.content$)); _ck(_v, 4, 0, currVal_0); }); }
export function View_TextPreviewComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_TextPreviewComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(0, [["textPreview", 2]], null, 0, null, View_TextPreviewComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.fileTooLarge$)); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TextPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "text-preview", [], null, null, null, View_TextPreviewComponent_0, RenderType_TextPreviewComponent)), i1.ɵdid(1, 114688, null, 0, i11.TextPreviewComponent, [i4.CURRENT_PREVIEW_FILE, i5.PREVIEW_URL_TRANSFORMER, i6.Settings, i7.AppHttpClient, i8.DomSanitizer, i9.CurrentUser], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextPreviewComponentNgFactory = i1.ɵccf("text-preview", i11.TextPreviewComponent, View_TextPreviewComponent_Host_0, {}, {}, []);
export { TextPreviewComponentNgFactory as TextPreviewComponentNgFactory };
