<section *ngIf="article" class="article-container" id="article">
    <h1 class="article-title">
        <span>{{article.title}}</span>
        <a mat-icon-button
           *ngIf="user.hasPermission('articles.update')"
           [routerLink]="['/help-center/manage/articles', article.id, 'edit']"
           class="edit-article-btn"
           target="_blank">
            <mat-icon svgIcon="edit"></mat-icon>
        </a>
    </h1>
    <div class="article-body" [innerHTML]="trustedArticleBody" updateArticleLinks></div>

    <div class="submit-ticket-container container">
        <span trans>Have more questions?</span>
        <a routerLink="/help-center/tickets/new" trans>Submit a Request</a>
    </div>

    <article-feedback [articleId]="article.id"></article-feedback>
</section>