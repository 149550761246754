var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NgZone, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Settings } from '../../core/config/settings.service';
import { Subject } from 'rxjs';
var RequestExtraCredentialsModalComponent = /** @class */ (function () {
    function RequestExtraCredentialsModalComponent(dialogRef, data, zone, settings) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.zone = zone;
        this.settings = settings;
        this.model = {};
        this.errors = {};
        this.onSubmit$ = new Subject();
    }
    RequestExtraCredentialsModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.zone.run(function () {
            _this.credentialsToRequest = _this.data.credentials;
        });
    };
    RequestExtraCredentialsModalComponent.prototype.shouldCredentialBeRequested = function (name) {
        return this.credentialsToRequest.indexOf(name) > -1;
    };
    RequestExtraCredentialsModalComponent.prototype.confirm = function () {
        // this.dialogRef.close(Object.assign({}, this.model));
        this.onSubmit$.next(__assign({}, this.model));
    };
    RequestExtraCredentialsModalComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    RequestExtraCredentialsModalComponent.prototype.handleErrors = function (response) {
        var _this = this;
        // we need to request user extra credentials again, for example
        // if email address user supplied previously already exists
        // we might need to request password for account with that email
        if (response['messages']['email']) {
            this.credentialsToRequest.push('password');
        }
        this.zone.run(function () {
            _this.errors = response.messages;
        });
    };
    return RequestExtraCredentialsModalComponent;
}());
export { RequestExtraCredentialsModalComponent };
