var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseFilePreview } from '../base-file-preview';
var FIVE_MB = 5242880;
var TextPreviewComponent = /** @class */ (function (_super) {
    __extends(TextPreviewComponent, _super);
    function TextPreviewComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.content$ = new BehaviorSubject('');
        _this.fileTooLarge$ = new BehaviorSubject(false);
        return _this;
    }
    TextPreviewComponent.prototype.ngOnInit = function () {
        if (this.file.file_size > FIVE_MB) {
            this.fileTooLarge$.next(true);
        }
        else {
            this.loadFileContents();
        }
    };
    TextPreviewComponent.prototype.loadFileContents = function () {
        var _this = this;
        this.getContents()
            .subscribe(function (content) {
            _this.content$.next(content);
        });
    };
    return TextPreviewComponent;
}(BaseFilePreview));
export { TextPreviewComponent };
