/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./article-content.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../common/core/translations/translate.directive";
import * as i5 from "../../../../../common/core/translations/translations.service";
import * as i6 from "../../../../../common/core/config/settings.service";
import * as i7 from "./article-content.component";
var styles_ArticleContentComponent = [i0.styles];
var RenderType_ArticleContentComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ArticleContentComponent, data: {} });
export { RenderType_ArticleContentComponent as RenderType_ArticleContentComponent };
function View_ArticleContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "content-item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onContentItemClick(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { fragment: [0, "fragment"], routerLink: [1, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.slug(_v.context.$implicit); var currVal_3 = i1.ɵEMPTY_ARRAY; _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit; _ck(_v, 2, 0, currVal_4); }); }
export function View_ArticleContentComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h4", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i4.TranslateDirective, [i1.ElementRef, i5.Translations, i6.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Contents"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ArticleContentComponent_1)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.content$)); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_ArticleContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "article-content", [], null, null, null, View_ArticleContentComponent_0, RenderType_ArticleContentComponent)), i1.ɵdid(1, 638976, null, 0, i7.ArticleContentComponent, [i2.Router, i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ArticleContentComponentNgFactory = i1.ɵccf("article-content", i7.ArticleContentComponent, View_ArticleContentComponent_Host_0, { article: "article" }, {}, []);
export { ArticleContentComponentNgFactory as ArticleContentComponentNgFactory };
