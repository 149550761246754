var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NavigationEnd, Router } from '@angular/router';
import { filter, finalize } from 'rxjs/operators';
import { Settings } from '../../../common/core/config/settings.service';
import { AppHttpClient } from '../../../common/core/http/app-http-client.service';
import { objToFormData } from '../../../common/core/utils/obj-to-form-data';
import { CurrentUser } from '../../../common/auth/current-user';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../../common/core/config/settings.service";
import * as i3 from "../../../common/core/http/app-http-client.service";
import * as i4 from "../../../common/auth/current-user";
var BACKEND_URI = 'search-term';
var SearchTermLoggerService = /** @class */ (function () {
    function SearchTermLoggerService(router, settings, http, currentUser) {
        this.router = router;
        this.settings = settings;
        this.http = http;
        this.currentUser = currentUser;
    }
    SearchTermLoggerService.prototype.log = function (term, results, categories) {
        if (categories === void 0) { categories = []; }
        // if previous search term starts with current term
        // we can assume user did not finish typing yet
        if (this.isSameSearchSession(term)) {
            this.updateSession({
                term: term,
                resultCount: results.length,
                categoryId: (categories && categories[0]) || null,
            });
        }
        else {
            var payload = __assign({}, this.session, { type: 'xhr ns' });
            this.resetSession();
            this.store(payload);
        }
    };
    SearchTermLoggerService.prototype.updateSession = function (data) {
        this.session = __assign({}, this.session, data);
    };
    SearchTermLoggerService.prototype.updateSessionAndStore = function (data) {
        this.updateSession(data);
        this.store();
    };
    SearchTermLoggerService.prototype.isSameSearchSession = function (term) {
        return !this.session.term ||
            term.indexOf(this.session.term) === 0 ||
            this.session.term.indexOf(term) === 0;
    };
    SearchTermLoggerService.prototype.store = function (payload) {
        var _this = this;
        payload = payload || __assign({}, this.session);
        if (!payload.term || payload.term.length < 3)
            return;
        payload.source = payload.source || 'hxr';
        payload.userId = this.currentUser.get('id');
        this.http.post(BACKEND_URI, payload)
            .pipe(finalize(function () { return _this.resetSession(); }))
            .subscribe();
    };
    SearchTermLoggerService.prototype.init = function () {
        var _this = this;
        this.resetSession();
        this.router.events.pipe(filter(function (e) { return e instanceof NavigationEnd; })).subscribe(function (e) {
            if (e.urlAfterRedirects.match('help-center\\/articles\\/.+')) {
                _this.session.clickedArticle = true;
                _this.session.page = 'hc';
                _this.store();
            }
            else if (e.urlAfterRedirects === 'help-center/tickets/new') {
                _this.session.createdTicket = true;
                _this.session.page = 'ticket';
                _this.store();
            }
            else {
                _this.session.page = 'hc';
            }
        });
        if (navigator.sendBeacon) {
            window.addEventListener('beforeunload', function () {
                var payload = __assign({}, _this.session);
                payload._token = _this.settings.csrfToken;
                payload.source = 'beacon';
                if (payload.term) {
                    navigator.sendBeacon(_this.settings.getBaseUrl() + 'secure/' + BACKEND_URI, objToFormData(payload));
                }
            });
        }
    };
    SearchTermLoggerService.prototype.resetSession = function () {
        this.session = {
            term: '',
            resultCount: 0,
            clickedArticle: false,
            createdTicket: false,
            source: null,
            page: 'hc',
            _token: null,
        };
    };
    SearchTermLoggerService.ngInjectableDef = i0.defineInjectable({ factory: function SearchTermLoggerService_Factory() { return new SearchTermLoggerService(i0.inject(i1.Router), i0.inject(i2.Settings), i0.inject(i3.AppHttpClient), i0.inject(i4.CurrentUser)); }, token: SearchTermLoggerService, providedIn: "root" });
    return SearchTermLoggerService;
}());
export { SearchTermLoggerService };
