var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit } from '@angular/core';
import { BaseFilePreview } from '../base-file-preview';
var AudioPreviewComponent = /** @class */ (function (_super) {
    __extends(AudioPreviewComponent, _super);
    function AudioPreviewComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.invalidMedia = false;
        return _this;
    }
    AudioPreviewComponent.prototype.ngOnInit = function () {
        this.invalidMedia = !this.canPlayVideo();
        if (!this.invalidMedia) {
            this.player().src = this.getSrc();
            this.player().play();
        }
    };
    AudioPreviewComponent.prototype.canPlayVideo = function () {
        return !!this.player().canPlayType(this.file.mime);
    };
    AudioPreviewComponent.prototype.player = function () {
        return this.playerEl.nativeElement;
    };
    return AudioPreviewComponent;
}(BaseFilePreview));
export { AudioPreviewComponent };
