import { EventEmitter } from '@angular/core';
import Echo from 'laravel-echo';
import 'pusher-js';
import { Settings } from '../../common/core/config/settings.service';
import { AppHttpClient } from '../../common/core/http/app-http-client.service';
import { CurrentUser } from '../../common/auth/current-user';
import * as i0 from "@angular/core";
import * as i1 from "../../common/core/config/settings.service";
import * as i2 from "../../common/core/http/app-http-client.service";
import * as i3 from "../../common/auth/current-user";
var BackendEvents = /** @class */ (function () {
    /**
     * BackendEvents Constructor.
     */
    function BackendEvents(settings, httpClient, currentUser) {
        this.settings = settings;
        this.httpClient = httpClient;
        this.currentUser = currentUser;
        /**
         * Fired when new ticket is created.
         */
        this.ticketCreated = new EventEmitter();
        /**
         * Fired when new ticket reply is created.
         */
        this.ticketReplyCreated = new EventEmitter();
        /**
         * Whether BackendEvents service has already been bootstrapped.
         */
        this.bootstrapped = false;
    }
    /**
     * Create observables for document events.
     */
    BackendEvents.prototype.init = function () {
        if (this.bootstrapped || !this.shouldInitPusher())
            return;
        this.echo = new Echo({
            broadcaster: 'pusher',
            key: this.settings.get('realtime.pusher_key'),
            authEndpoint: 'secure/broadcasting/auth',
            csrfToken: this.settings.csrfToken,
        });
        this.subscribeToChannel();
        if (this.channel) {
            this.setSocketIdHeader();
            this.listenForTicketReplyCreatedEvent();
            this.listenForTicketCreatedEvent();
        }
        this.bootstrapped = true;
    };
    /**
     * Subscribe to echo channel current user has access to.
     */
    BackendEvents.prototype.subscribeToChannel = function () {
        var channel = this.getChannelName();
        if (!channel)
            return;
        this.channel = this.echo.private(channel);
    };
    /**
     * set "X-Socket-ID" header to enable laravel
     * "toOthers" broadcasting functionality
     */
    BackendEvents.prototype.setSocketIdHeader = function () {
        this.channel.on('pusher:subscription_succeeded', function () {
            // TODO: this.httpClient.setDefaultHeader('X-Socket-ID', this.echo.socketId());
        });
    };
    /**
     * Listen for new ticket reply created event from backend.
     */
    BackendEvents.prototype.listenForTicketReplyCreatedEvent = function () {
        var _this = this;
        this.channel.listen('TicketReplyCreated', function (e) {
            _this.httpClient.get('replies/' + e.replyId).subscribe(function (reply) {
                _this.ticketReplyCreated.emit(reply);
            });
        });
    };
    /**
     * Listen for new ticket created event from backend.
     */
    BackendEvents.prototype.listenForTicketCreatedEvent = function () {
        var _this = this;
        this.channel.listen('TicketCreated', function (e) {
            _this.httpClient.get('tickets/' + e.ticketId).subscribe(function (ticket) {
                _this.ticketCreated.emit(ticket);
            });
        });
    };
    /**
     * Get tickets channel user should listen on based on their permissions.
     */
    BackendEvents.prototype.getChannelName = function () {
        if (!this.currentUser.isLoggedIn())
            return;
        var name = 'App.User.' + this.currentUser.get('id');
        if (this.currentUser.hasPermissions(['replies.view', 'tickets.view'])) {
            name = 'tickets.global';
        }
        return name;
    };
    /**
     * Check if realtime is enabled and pusher key is set.
     */
    BackendEvents.prototype.shouldInitPusher = function () {
        return this.settings.get('realtime.pusher_key') && this.settings.get('realtime.enable');
    };
    BackendEvents.ngInjectableDef = i0.defineInjectable({ factory: function BackendEvents_Factory() { return new BackendEvents(i0.inject(i1.Settings), i0.inject(i2.AppHttpClient), i0.inject(i3.CurrentUser)); }, token: BackendEvents, providedIn: "root" });
    return BackendEvents;
}());
export { BackendEvents };
