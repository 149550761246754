var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { SocialAuthService } from '../social-auth.service';
import { CurrentUser } from '../current-user';
import { Router } from '@angular/router';
import { Settings } from '../../core/config/settings.service';
import { Toast } from '../../core/ui/toast.service';
import { Bootstrapper } from '../../core/bootstrapper.service';
import { RecaptchaService } from '../../core/services/recaptcha.service';
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(auth, socialAuth, settings, user, router, toast, bootstrapper, recaptcha) {
        this.auth = auth;
        this.socialAuth = socialAuth;
        this.settings = settings;
        this.user = user;
        this.router = router;
        this.toast = toast;
        this.bootstrapper = bootstrapper;
        this.recaptcha = recaptcha;
        this.model = {};
        this.errors = {};
        this.isLoading = false;
    }
    RegisterComponent.prototype.ngOnInit = function () {
        if (this.recaptcha.enabledFor('registration')) {
            this.recaptcha.load();
        }
    };
    RegisterComponent.prototype.register = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isLoading = true;
                        _a = this.recaptcha.enabledFor('registration');
                        if (!_a) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.recaptcha.verify('registration')];
                    case 1:
                        _a = !(_b.sent());
                        _b.label = 2;
                    case 2:
                        if (_a) {
                            this.isLoading = false;
                            return [2 /*return*/, this.toast.open('Could not verify you are human.')];
                        }
                        this.auth.register(this.model).subscribe(function (response) {
                            _this.isLoading = false;
                            if (_this.settings.get('require_email_confirmation')) {
                                _this.router.navigate(['/login']).then(function () {
                                    _this.toast.open('We have sent you an email with instructions on how to activate your account.', { duration: 6000 });
                                });
                            }
                            else {
                                _this.bootstrapper.bootstrap(response.data);
                                _this.router.navigate([_this.auth.getRedirectUri()]).then(function () {
                                    _this.toast.open('Registered successfully.');
                                });
                            }
                        }, function (response) {
                            _this.errors = response['messages'];
                            _this.isLoading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return RegisterComponent;
}());
export { RegisterComponent };
