/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./article.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "./update-article-links.directive";
import * as i12 from "../../../../common/core/translations/translate.directive";
import * as i13 from "../../../../common/core/translations/translations.service";
import * as i14 from "../../../../common/core/config/settings.service";
import * as i15 from "../article-feedback/article-feedback.component.ngfactory";
import * as i16 from "../article-feedback/article-feedback.component";
import * as i17 from "../help-center.service";
import * as i18 from "./article.component";
import * as i19 from "@angular/platform-browser";
import * as i20 from "../../../../common/auth/current-user";
var styles_ArticleComponent = [i0.styles];
var RenderType_ArticleComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ArticleComponent, data: {} });
export { RenderType_ArticleComponent as RenderType_ArticleComponent };
function View_ArticleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [["class", "edit-article-btn"], ["mat-icon-button", ""], ["target", "_blank"]], [[1, "target", 0], [8, "href", 4], [1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._haltDisabledEvents($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatAnchor_0, i2.RenderType_MatAnchor)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), i1.ɵpad(2, 3), i1.ɵdid(3, 180224, null, 0, i5.MatAnchor, [i6.Platform, i7.FocusMonitor, i1.ElementRef, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["\n            "])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "edit"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n        "]))], function (_ck, _v) { var _co = _v.component; var currVal_6 = "_blank"; var currVal_7 = _ck(_v, 2, 0, "/help-center/manage/articles", _co.article.id, "edit"); _ck(_v, 1, 0, currVal_6, currVal_7); var currVal_10 = "edit"; _ck(_v, 6, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; var currVal_2 = (i1.ɵnov(_v, 3).disabled ? (0 - 1) : (i1.ɵnov(_v, 3).tabIndex || 0)); var currVal_3 = (i1.ɵnov(_v, 3).disabled || null); var currVal_4 = i1.ɵnov(_v, 3).disabled.toString(); var currVal_5 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = i1.ɵnov(_v, 6).inline; var currVal_9 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_8, currVal_9); }); }
function View_ArticleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "section", [["class", "article-container"], ["id", "article"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 7, "h1", [["class", "article-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArticleComponent_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "article-body"], ["updateArticleLinks", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(12, 4210688, null, 0, i11.UpdateArticleLinksDirective, [i1.ElementRef, i3.Router], null, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(14, 0, null, null, 10, "div", [["class", "submit-ticket-container container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(17, 4341760, null, 0, i12.TranslateDirective, [i1.ElementRef, i13.Translations, i14.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Have more questions?"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(20, 0, null, null, 3, "a", [["routerLink", "/help-center/tickets/new"], ["trans", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(22, 4341760, null, 0, i12.TranslateDirective, [i1.ElementRef, i13.Translations, i14.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Submit a Request"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(26, 0, null, null, 1, "article-feedback", [], null, null, null, i15.View_ArticleFeedbackComponent_0, i15.RenderType_ArticleFeedbackComponent)), i1.ɵdid(27, 49152, null, 0, i16.ArticleFeedbackComponent, [i17.HelpCenterService], { articleId: [0, "articleId"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.user.hasPermission("articles.update"); _ck(_v, 8, 0, currVal_1); var currVal_5 = "/help-center/tickets/new"; _ck(_v, 21, 0, currVal_5); var currVal_6 = _co.article.id; _ck(_v, 27, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.article.title; _ck(_v, 5, 0, currVal_0); var currVal_2 = _co.trustedArticleBody; _ck(_v, 11, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 21).target; var currVal_4 = i1.ɵnov(_v, 21).href; _ck(_v, 20, 0, currVal_3, currVal_4); }); }
export function View_ArticleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArticleComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.article; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ArticleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "article", [], null, null, null, View_ArticleComponent_0, RenderType_ArticleComponent)), i1.ɵdid(1, 8503296, null, 0, i18.ArticleComponent, [i19.DomSanitizer, i3.ActivatedRoute, i3.Router, i17.HelpCenterService, i20.CurrentUser], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ArticleComponentNgFactory = i1.ɵccf("article", i18.ArticleComponent, View_ArticleComponent_Host_0, { article: "article" }, {}, []);
export { ArticleComponentNgFactory as ArticleComponentNgFactory };
