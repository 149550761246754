import { Injector } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DefaultPreviews } from './available-previews';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { CURRENT_PREVIEW_FILE } from './current-preview-file';
import { DefaultPreviewComponent } from './default-preview/default-preview.component';
import { filter } from 'rxjs/operators';
import { PREVIEW_URL_TRANSFORMER } from './preview-url-transformer';
import * as i0 from "@angular/core";
import * as i1 from "./available-previews";
var PreviewFilesService = /** @class */ (function () {
    function PreviewFilesService(availablePreviews, injector) {
        this.availablePreviews = availablePreviews;
        this.injector = injector;
        this.files = [];
        this.portal = new BehaviorSubject(null);
        this.meta = new BehaviorSubject({});
        this.previewUriTransformer = null;
        this.download = new Subject();
    }
    PreviewFilesService.prototype.pagination = function () {
        return this.meta
            .pipe(filter(function (data) { return data && !!data.entry; }));
    };
    PreviewFilesService.prototype.getCurrent = function () {
        return this.meta.value.entry;
    };
    PreviewFilesService.prototype.getAllEntries = function () {
        return this.files;
    };
    PreviewFilesService.prototype.getPortal = function () {
        return this.portal.asObservable();
    };
    PreviewFilesService.prototype.showNext = function () {
        if (!this.meta.value.haveNext)
            return;
        this.updateMeta('next');
        this.updatePortal();
    };
    PreviewFilesService.prototype.showPrevious = function () {
        if (!this.meta.value.havePrevious)
            return;
        this.updateMeta('previous');
        this.updatePortal();
    };
    PreviewFilesService.prototype.updateMeta = function (dir) {
        var newPointer = 0;
        if (typeof dir === 'string') {
            var oldPointer = this.meta.value.pointer;
            newPointer = dir === 'next' ? oldPointer + 1 : oldPointer - 1;
        }
        else if (typeof dir === 'number') {
            newPointer = dir;
        }
        this.meta.next({
            pointer: newPointer,
            entry: this.files[newPointer],
            total: this.files.length,
            haveNext: (newPointer + 1) < this.files.length,
            havePrevious: (newPointer - 1) > -1,
        });
    };
    PreviewFilesService.prototype.updatePortal = function () {
        var current = this.getCurrent();
        var comp = (current && this.availablePreviews[current.type]) || DefaultPreviewComponent;
        this.portal.next(new ComponentPortal(comp, null, this.createInjector()));
    };
    PreviewFilesService.prototype.set = function (files, activeFile) {
        if (!files || files.length === 0)
            return;
        this.files = files.filter(function (entry) { return entry.type !== 'folder'; });
        this.updateMeta(activeFile);
        this.updatePortal();
    };
    PreviewFilesService.prototype.createInjector = function () {
        var injectionTokens = new WeakMap();
        // set currently active preview file
        injectionTokens.set(CURRENT_PREVIEW_FILE, this.getCurrent());
        // override preview backend uri, if specified
        if (this.previewUriTransformer) {
            injectionTokens.set(PREVIEW_URL_TRANSFORMER, this.previewUriTransformer);
        }
        return new PortalInjector(this.injector, injectionTokens);
    };
    PreviewFilesService.prototype.destroy = function () {
        this.meta.next({});
        this.files = null;
        this.portal.next(null);
    };
    /**
     * Provide transformer function for preview file backend url.
     * (for changing base uri, adding url params etc)
     */
    PreviewFilesService.prototype.setPreviewUriTransformer = function (transformer) {
        this.previewUriTransformer = transformer;
    };
    PreviewFilesService.ngInjectableDef = i0.defineInjectable({ factory: function PreviewFilesService_Factory() { return new PreviewFilesService(i0.inject(i1.AVAILABLE_PREVIEWS), i0.inject(i0.INJECTOR)); }, token: PreviewFilesService, providedIn: "root" });
    return PreviewFilesService;
}());
export { PreviewFilesService };
