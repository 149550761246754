/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-preview.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./image-preview.component";
import * as i3 from "../current-preview-file";
import * as i4 from "../preview-url-transformer";
import * as i5 from "../../core/config/settings.service";
import * as i6 from "../../core/http/app-http-client.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../auth/current-user";
var styles_ImagePreviewComponent = [i0.styles];
var RenderType_ImagePreviewComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ImagePreviewComponent, data: {} });
export { RenderType_ImagePreviewComponent as RenderType_ImagePreviewComponent };
export function View_ImagePreviewComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "preview-object"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSafeSrc(); _ck(_v, 0, 0, currVal_0); }); }
export function View_ImagePreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "image-preview", [], null, null, null, View_ImagePreviewComponent_0, RenderType_ImagePreviewComponent)), i1.ɵdid(1, 49152, null, 0, i2.ImagePreviewComponent, [i3.CURRENT_PREVIEW_FILE, i4.PREVIEW_URL_TRANSFORMER, i5.Settings, i6.AppHttpClient, i7.DomSanitizer, i8.CurrentUser], null, null)], null, null); }
var ImagePreviewComponentNgFactory = i1.ɵccf("image-preview", i2.ImagePreviewComponent, View_ImagePreviewComponent_Host_0, {}, {}, []);
export { ImagePreviewComponentNgFactory as ImagePreviewComponentNgFactory };
