/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./google-docs-viewer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../default-preview/default-preview.component.ngfactory";
import * as i3 from "../default-preview/default-preview.component";
import * as i4 from "../current-preview-file";
import * as i5 from "../preview-url-transformer";
import * as i6 from "../../core/config/settings.service";
import * as i7 from "../../core/http/app-http-client.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../auth/current-user";
import * as i10 from "@angular/common";
import * as i11 from "../../core/ui/loading-indicator/loading-indicator.component.ngfactory";
import * as i12 from "../../core/ui/loading-indicator/loading-indicator.component";
import * as i13 from "./google-docs-viewer.component";
var styles_GoogleDocsViewerComponent = [i0.styles];
var RenderType_GoogleDocsViewerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_GoogleDocsViewerComponent, data: {} });
export { RenderType_GoogleDocsViewerComponent as RenderType_GoogleDocsViewerComponent };
function View_GoogleDocsViewerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["iframe", 1]], null, 0, "iframe", [["class", "preview-object"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "pop-out-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openInNewWindow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
function View_GoogleDocsViewerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "default-preview", [], null, null, null, i2.View_DefaultPreviewComponent_0, i2.RenderType_DefaultPreviewComponent)), i1.ɵdid(2, 49152, null, 0, i3.DefaultPreviewComponent, [i4.CURRENT_PREVIEW_FILE, i5.PREVIEW_URL_TRANSFORMER, i6.Settings, i7.AppHttpClient, i8.DomSanitizer, i9.CurrentUser], null, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_GoogleDocsViewerComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { iframe: 0 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_GoogleDocsViewerComponent_1)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(0, [["defaultPreview", 2]], null, 0, null, View_GoogleDocsViewerComponent_2)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "loading-indicator", [["class", "overlay"]], [[40, "@visibility", 0]], null, null, i11.View_LoadingIndicatorComponent_0, i11.RenderType_LoadingIndicatorComponent)), i1.ɵdid(8, 49152, null, 0, i12.LoadingIndicatorComponent, [], { isVisible: [0, "isVisible"] }, null), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = !i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.showDefaultPreview$)); var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.loading$)); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 8).isVisible; _ck(_v, 7, 0, currVal_2); }); }
export function View_GoogleDocsViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "google-docs-viewer", [], null, null, null, View_GoogleDocsViewerComponent_0, RenderType_GoogleDocsViewerComponent)), i1.ɵdid(1, 4243456, null, 0, i13.GoogleDocsViewerComponent, [i4.CURRENT_PREVIEW_FILE, i5.PREVIEW_URL_TRANSFORMER, i6.Settings, i7.AppHttpClient, i8.DomSanitizer, i9.CurrentUser], null, null)], null, null); }
var GoogleDocsViewerComponentNgFactory = i1.ɵccf("google-docs-viewer", i13.GoogleDocsViewerComponent, View_GoogleDocsViewerComponent_Host_0, {}, {}, []);
export { GoogleDocsViewerComponentNgFactory as GoogleDocsViewerComponentNgFactory };
