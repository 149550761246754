var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, AfterViewInit } from '@angular/core';
import { BaseFilePreview } from '../base-file-preview';
import { BehaviorSubject } from 'rxjs';
var GoogleDocsViewerComponent = /** @class */ (function (_super) {
    __extends(GoogleDocsViewerComponent, _super);
    function GoogleDocsViewerComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading$ = new BehaviorSubject(true);
        _this.showDefaultPreview$ = new BehaviorSubject(false);
        return _this;
    }
    GoogleDocsViewerComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // google docs viewer only supports file up to 25MB
        if (this.file.file_size > 25000000) {
            return this.showDefaultPreview();
        }
        this.iframe.nativeElement.onload = function () {
            clearTimeout(_this.timeoutRef);
            _this.stopLoading();
        };
        this.getIframeSrc().then(function (url) {
            _this.iframe.nativeElement.src = url;
        }).catch(function () {
            _this.showDefaultPreview();
        });
        // if google docs preview iframe is not loaded
        // after 6 seconds, bail and show default preview
        this.timeoutRef = setTimeout(function () {
            _this.showDefaultPreview();
        }, 5000);
    };
    GoogleDocsViewerComponent.prototype.stopLoading = function () {
        this.loading$.next(false);
    };
    GoogleDocsViewerComponent.prototype.showDefaultPreview = function () {
        this.stopLoading();
        this.showDefaultPreview$.next(true);
    };
    GoogleDocsViewerComponent.prototype.openInNewWindow = function () {
        window.open(window.location.href, '_blank');
    };
    GoogleDocsViewerComponent.prototype.getIframeSrc = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var previewUrl = _this.getSrc();
            // if we're not trying to preview shareable link we will need to generate
            // preview token, otherwise google won't be able to access this file
            if (previewUrl.indexOf('shareable_link') === -1) {
                _this.http.post("uploads/" + _this.file.id + "/add-preview-token").subscribe(function (response) {
                    previewUrl += "?preview_token=" + response.preview_token;
                    resolve(_this.getGoogleDocsUrl(previewUrl));
                }, function () { return reject; });
            }
            else {
                resolve(_this.getGoogleDocsUrl(previewUrl));
            }
        });
    };
    GoogleDocsViewerComponent.prototype.getGoogleDocsUrl = function (previewUrl) {
        return 'https://docs.google.com/gview?embedded=true&url=' + encodeURIComponent(previewUrl);
    };
    return GoogleDocsViewerComponent;
}(BaseFilePreview));
export { GoogleDocsViewerComponent };
