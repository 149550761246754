/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help-center.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./home/multi-product/multi-product.component.ngfactory";
import * as i3 from "./home/multi-product/multi-product.component";
import * as i4 from "../shared/hc-urls.service";
import * as i5 from "../../../common/core/config/settings.service";
import * as i6 from "./home/article-grid/article-grid.component.ngfactory";
import * as i7 from "./home/article-grid/article-grid.component";
import * as i8 from "@angular/common";
import * as i9 from "../../../common/admin/pagination/paginator.service";
import * as i10 from "@angular/router";
import * as i11 from "../../../common/core/http/app-http-client.service";
import * as i12 from "./help-center.component";
var styles_HelpCenterComponent = [i0.styles];
var RenderType_HelpCenterComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HelpCenterComponent, data: {} });
export { RenderType_HelpCenterComponent as RenderType_HelpCenterComponent };
function View_HelpCenterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "multi-product", [], null, null, null, i2.View_MultiProductComponent_0, i2.RenderType_MultiProductComponent)), i1.ɵdid(1, 49152, null, 0, i3.MultiProductComponent, [i4.HcUrls, i5.Settings], { categories: [0, "categories"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.categories; _ck(_v, 1, 0, currVal_0); }, null); }
function View_HelpCenterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "article-grid", [], null, null, null, i6.View_ArticleGridComponent_0, i6.RenderType_ArticleGridComponent)), i1.ɵdid(2, 49152, null, 0, i7.ArticleGridComponent, [i4.HcUrls, i5.Settings], { categories: [0, "categories"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.categories; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_HelpCenterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpCenterComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(0, [["articleGrid", 2]], null, 0, null, View_HelpCenterComponent_2)), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.settings.get("hc_home.template") === "multiProduct"); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_HelpCenterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "help-center", [], null, null, null, View_HelpCenterComponent_0, RenderType_HelpCenterComponent)), i1.ɵprd(4608, null, i9.Paginator, i9.Paginator, [i10.Router, i11.AppHttpClient]), i1.ɵdid(2, 114688, null, 0, i12.HelpCenterComponent, [i10.ActivatedRoute, i5.Settings], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var HelpCenterComponentNgFactory = i1.ɵccf("help-center", i12.HelpCenterComponent, View_HelpCenterComponent_Host_0, {}, {}, []);
export { HelpCenterComponentNgFactory as HelpCenterComponentNgFactory };
