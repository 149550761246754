var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { AppHttpClient } from '../../core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../core/http/app-http-client.service";
export var DEFAULT_PAGINATOR_PARAMS = {
    order_by: 'updated_at',
    order_dir: 'desc',
    page: 1,
    per_page: 15,
};
var Paginator = /** @class */ (function () {
    function Paginator(router, http) {
        this.router = router;
        this.http = http;
        this.params$ = new BehaviorSubject(DEFAULT_PAGINATOR_PARAMS);
        this.lastResponse$ = new BehaviorSubject(null);
        this.initiated = false;
        this.loading$ = new BehaviorSubject(false);
    }
    Object.defineProperty(Paginator.prototype, "pagination$", {
        get: function () {
            return this.lastResponse$.asObservable().pipe(filter(function (p) { return !!p; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paginator.prototype, "noResults$", {
        get: function () {
            // only return TRUE if data has already been
            // loaded from backend and there were not results
            return this.pagination$.pipe(map(function (p) { return p.data.length === 0; }));
        },
        enumerable: true,
        configurable: true
    });
    Paginator.prototype.paginate = function (userParams, url) {
        if (userParams === void 0) { userParams = {}; }
        var queryParams = this.router.routerState.root.snapshot.queryParams;
        this.params$.next(__assign({}, DEFAULT_PAGINATOR_PARAMS, queryParams, userParams));
        if (!this.initiated) {
            this.init(url);
        }
        // prevent multiple subscriptions
        return this.pagination$.pipe(take(1));
    };
    Paginator.prototype.init = function (uri) {
        var _this = this;
        this.backendUri = uri;
        this.subscription = this.params$.pipe(switchMap(function (params) {
            _this.loading$.next(true);
            return _this.http.get(_this.backendUri, params)
                .pipe(tap(function () { return _this.updateQueryParams(params); }), finalize(function () { return _this.loading$.next(false); }));
        })).subscribe(function (response) {
            _this.lastResponse$.next(response.pagination);
        });
        this.initiated = true;
    };
    /**
     * Update query params of currently active url.
     */
    Paginator.prototype.updateQueryParams = function (params) {
        if (params === void 0) { params = {}; }
        var defaults = DEFAULT_PAGINATOR_PARAMS;
        // there's no need to reflect default
        // or non-common params in query, filter them out
        var filtered = Object.keys(params)
            .filter(function (key) { return defaults[key] && params[key] !== defaults[key]; })
            .reduce(function (obj, key) {
            obj[key] = params[key];
            return obj;
        }, {});
        this.router.navigate([], { queryParams: filtered, replaceUrl: true });
    };
    Paginator.prototype.destroy = function () {
        this.subscription && this.subscription.unsubscribe();
    };
    Paginator.ngInjectableDef = i0.defineInjectable({ factory: function Paginator_Factory() { return new Paginator(i0.inject(i1.Router), i0.inject(i2.AppHttpClient)); }, token: Paginator, providedIn: "root" });
    return Paginator;
}());
export { Paginator };
