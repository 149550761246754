/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./article-feedback.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/core/translations/translate.directive";
import * as i3 from "../../../../common/core/translations/translations.service";
import * as i4 from "../../../../common/core/config/settings.service";
import * as i5 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i6 from "@angular/material/icon";
import * as i7 from "@angular/common";
import * as i8 from "./article-feedback.component";
import * as i9 from "../help-center.service";
var styles_ArticleFeedbackComponent = [i0.styles];
var RenderType_ArticleFeedbackComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ArticleFeedbackComponent, data: {} });
export { RenderType_ArticleFeedbackComponent as RenderType_ArticleFeedbackComponent };
function View_ArticleFeedbackComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "title"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(3, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Was this article helpful?"])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(6, 0, null, null, 21, "div", [["class", "feedback-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 8, "button", [["class", "button success yes"], ["type", "button"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitFeedback(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "check"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(11, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(14, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Yes"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(18, 0, null, null, 8, "button", [["class", "button danger no"], ["type", "button"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitFeedback(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(21, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(23, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(24, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["No"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_3 = "check"; _ck(_v, 11, 0, currVal_3); var currVal_7 = "close"; _ck(_v, 21, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.wasHelpful === true); _ck(_v, 8, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 11).inline; var currVal_2 = (((i1.ɵnov(_v, 11).color !== "primary") && (i1.ɵnov(_v, 11).color !== "accent")) && (i1.ɵnov(_v, 11).color !== "warn")); _ck(_v, 10, 0, currVal_1, currVal_2); var currVal_4 = (_co.wasHelpful === false); _ck(_v, 18, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 21).inline; var currVal_6 = (((i1.ɵnov(_v, 21).color !== "primary") && (i1.ɵnov(_v, 21).color !== "accent")) && (i1.ɵnov(_v, 21).color !== "warn")); _ck(_v, 20, 0, currVal_5, currVal_6); }); }
function View_ArticleFeedbackComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "feedback-submitted-notification"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["\n    Thank you! Your feedback will help us improve the support experience. If you need more help, try searching for what you need at the top of the page.\n"]))], null, null); }
export function View_ArticleFeedbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArticleFeedbackComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArticleFeedbackComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.feedbackAlreadySubmitted; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.feedbackAlreadySubmitted; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ArticleFeedbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "article-feedback", [], null, null, null, View_ArticleFeedbackComponent_0, RenderType_ArticleFeedbackComponent)), i1.ɵdid(1, 49152, null, 0, i8.ArticleFeedbackComponent, [i9.HelpCenterService], null, null)], null, null); }
var ArticleFeedbackComponentNgFactory = i1.ɵccf("article-feedback", i8.ArticleFeedbackComponent, View_ArticleFeedbackComponent_Host_0, { articleId: "articleId" }, {}, []);
export { ArticleFeedbackComponentNgFactory as ArticleFeedbackComponentNgFactory };
