/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./default-preview.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../core/translations/translate.directive";
import * as i3 from "../../core/translations/translations.service";
import * as i4 from "../../core/config/settings.service";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./default-preview.component";
import * as i11 from "../current-preview-file";
import * as i12 from "../preview-url-transformer";
import * as i13 from "../../core/http/app-http-client.service";
import * as i14 from "@angular/platform-browser";
import * as i15 from "../../auth/current-user";
var styles_DefaultPreviewComponent = [i0.styles];
var RenderType_DefaultPreviewComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DefaultPreviewComponent, data: {} });
export { RenderType_DefaultPreviewComponent as RenderType_DefaultPreviewComponent };
export function View_DefaultPreviewComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "panel preview-object"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "message"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(3, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "button", [["color", "primary"], ["mat-raised-button", ""], ["trans", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(7, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), i1.ɵdid(8, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Download"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_3 = "primary"; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getMessage(); _ck(_v, 4, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 8).disabled || null); var currVal_2 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_1, currVal_2); }); }
export function View_DefaultPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "default-preview", [], null, null, null, View_DefaultPreviewComponent_0, RenderType_DefaultPreviewComponent)), i1.ɵdid(1, 49152, null, 0, i10.DefaultPreviewComponent, [i11.CURRENT_PREVIEW_FILE, i12.PREVIEW_URL_TRANSFORMER, i4.Settings, i13.AppHttpClient, i14.DomSanitizer, i15.CurrentUser], null, null)], null, null); }
var DefaultPreviewComponentNgFactory = i1.ɵccf("default-preview", i10.DefaultPreviewComponent, View_DefaultPreviewComponent_Host_0, { message: "message" }, {}, []);
export { DefaultPreviewComponentNgFactory as DefaultPreviewComponentNgFactory };
