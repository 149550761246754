/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./breadcrumbs.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./breadcrumbs.component";
import * as i7 from "../../shared/hc-urls.service";
import * as i8 from "../../../../common/core/translations/translations.service";
var styles_BreadCrumbsComponent = [i0.styles];
var RenderType_BreadCrumbsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_BreadCrumbsComponent, data: {} });
export { RenderType_BreadCrumbsComponent as RenderType_BreadCrumbsComponent };
function View_BreadCrumbsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "separator size-18 mat-icon notranslate"], ["role", "img"], ["svgIcon", "keyboard-arrow-right"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "keyboard-arrow-right"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BreadCrumbsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [["class", "item"]], [[2, "active", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadCrumbsComponent_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "item-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_3 = _v.context.$implicit.link; _ck(_v, 1, 0, currVal_3); var currVal_4 = !_v.context.first; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.last; var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_5); }); }
export function View_BreadCrumbsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadCrumbsComponent_1)), i1.ɵdid(1, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BreadCrumbsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "breadcrumbs", [], null, null, null, View_BreadCrumbsComponent_0, RenderType_BreadCrumbsComponent)), i1.ɵdid(1, 573440, null, 0, i6.BreadCrumbsComponent, [i7.HcUrls, i4.ActivatedRoute, i8.Translations], null, null)], null, null); }
var BreadCrumbsComponentNgFactory = i1.ɵccf("breadcrumbs", i6.BreadCrumbsComponent, View_BreadCrumbsComponent_Host_0, { resource: "resource", resourceType: "resourceType" }, {}, []);
export { BreadCrumbsComponentNgFactory as BreadCrumbsComponentNgFactory };
