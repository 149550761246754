/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./image-or-icon.component";
import * as i5 from "../../config/settings.service";
var styles_ImageOrIconComponent = [];
var RenderType_ImageOrIconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImageOrIconComponent, data: {} });
export { RenderType_ImageOrIconComponent as RenderType_ImageOrIconComponent };
function View_ImageOrIconComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.className; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; var currVal_1 = _co.alt; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ImageOrIconComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.className; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.settings.getBaseUrl(true) + _co.src); var currVal_1 = _co.alt; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ImageOrIconComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i0.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.className; _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.src; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).inline; var currVal_1 = (((i0.ɵnov(_v, 2).color !== "primary") && (i0.ɵnov(_v, 2).color !== "accent")) && (i0.ɵnov(_v, 2).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ImageOrIconComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageOrIconComponent_1)), i0.ɵdid(5, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageOrIconComponent_2)), i0.ɵdid(8, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageOrIconComponent_3)), i0.ɵdid(11, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.type$)); _ck(_v, 1, 0, currVal_0); var currVal_1 = "absolute"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "relative"; _ck(_v, 8, 0, currVal_2); var currVal_3 = "icon"; _ck(_v, 11, 0, currVal_3); }, null); }
export function View_ImageOrIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "image-or-icon", [], null, null, null, View_ImageOrIconComponent_0, RenderType_ImageOrIconComponent)), i0.ɵdid(1, 573440, null, 0, i4.ImageOrIconComponent, [i5.Settings], null, null)], null, null); }
var ImageOrIconComponentNgFactory = i0.ɵccf("image-or-icon", i4.ImageOrIconComponent, View_ImageOrIconComponent_Host_0, { src: "src", alt: "alt", className: "className" }, {}, []);
export { ImageOrIconComponentNgFactory as ImageOrIconComponentNgFactory };
