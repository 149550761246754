<hc-header></hc-header>

<div class="content">
    <ng-container *ngFor="let category of categories">
        <section class="category" *ngIf="!category.hidden">

            <div class="category-info">
                <h2 class="category-name"><a class="text" [routerLink]="urls.category(category)">{{category.name}}</a></h2>
                <p class="category-description" *ngIf="category.description">{{category.description}}</p>
            </div>

            <div class="articles-list">
                <a class="article" *ngFor="let article of category.articles" [routerLink]="urls.article(article)">
                    <mat-icon svgIcon="description"></mat-icon>
                    <span class="text">{{article.title}}</span>
                </a>
            </div>

            <div class="child-categories">
                <ng-container *ngFor="let child of category.children">
                    <div class="child-category" *ngIf="!shouldHideCategory(child)">

                        <div class="title">
                            <mat-icon [svgIcon]="child.image" *ngIf="child.image"></mat-icon>
                            <h3 class="child-category-name"><a [routerLink]="urls.category(child)">{{child.name}} ({{child.articles_count}})</a></h3>
                        </div>

                        <div class="articles-list">
                            <a class="article" *ngFor="let article of child.articles" [routerLink]="urls.article(article, child)">
                                <mat-icon svgIcon="description"></mat-icon>
                                <span class="text">{{article.title}}</span>
                            </a>
                        </div>
                    </div>
                </ng-container>
            </div>

        </section>
    </ng-container>
</div>

<customer-footer *ngIf="!settings.get('hc.hide_footer')"></customer-footer>