import { ActivatedRoute } from '@angular/router';
import { slugifyString } from '../../../common/core/utils/slugify-string';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var HcUrls = /** @class */ (function () {
    function HcUrls(route) {
        this.route = route;
    }
    HcUrls.prototype.article = function (article, category) {
        var base = ['/help-center/articles'];
        if (!category && article.categories) {
            category = article.categories[0];
        }
        if (category) {
            if (category.parent_id)
                base.push(category.parent_id);
            base.push(category.id);
        }
        return base.concat([article.id, this.getSlug(article)]);
    };
    HcUrls.prototype.category = function (category) {
        return ['/help-center/categories', category.id, this.getSlug(category)];
    };
    HcUrls.prototype.customerTicketList = function () {
        return ['/help-center/tickets'];
    };
    HcUrls.prototype.searchPage = function (query) {
        return ['/help-center/search', query];
    };
    HcUrls.prototype.getSlug = function (resource) {
        if (resource.slug) {
            return resource.slug;
        }
        else {
            return slugifyString(resource.title || resource.name);
        }
    };
    HcUrls.ngInjectableDef = i0.defineInjectable({ factory: function HcUrls_Factory() { return new HcUrls(i0.inject(i1.ActivatedRoute)); }, token: HcUrls, providedIn: "root" });
    return HcUrls;
}());
export { HcUrls };
